import 'regenerator-runtime/runtime';
import * as ReactDOM from 'react-dom/client';
import React from 'react';
import '../../../../global.d.ts';
import FLoading from './FLoading';

const AppLoader = React.lazy(async () => {
  return (await Promise.all([import('./AppLoader')]))[0];
});

const root = ReactDOM.createRoot(document.getElementById('web'));

root.render(
  <React.Suspense fallback={<FLoading />}>
    <AppLoader />
  </React.Suspense>
);
